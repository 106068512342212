<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li> {{ this.$t('general.requests_my_demands') }}</li>
            <li>Satınalma Talepleri</li>
          </ul>
        </nav>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left">
            <span>
              Satınalma Talepleri ({{ items.total ? items.total : 0 }})
            </span>
          </h2>
        </div>
      </div>

      <div class="uk-alert-success position-relative" uk-alert v-if="successMessage != null">
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green" class="m-0">
          <i class="fa fa-check d-inline-flex mr-2" aria-hidden="true"></i>{{ successMessage }}
        </p>
      </div>
      <div class="uk-alert-danger bg-soft-danger position_relative" uk-alert v-if="errorMessage != null">
        <a class="uk-alert-close" uk-close></a>
        <p style="color: red" class="m-0">{{ errorMessage }}</p>
      </div>
      <div class="card">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="d-flex justify-content-between align-items-center">
            <!-- <h4 class="d-inline-block mb-0"></h4> -->
            <!-- <div>
              <a
                  href="#"
                  class="btn btn-icon btn-hover btn-circle"
                  aria-expanded="false"
              >
                <i class="uil-search"></i>
              </a>
              <div
                  class="uk-drop"
                  uk-drop="mode: hover; pos: right-center; offset: 0"
                  style="left: 91px; top: 159.297px"
              >
                <form class="uk-search uk-search-navbar uk-width-1-1">
                  <input
                      class="uk-search-input shadow-0 uk-form-small"
                      type="search"
                      placeholder="Ara"
                      autofocus="off"
                      v-model="filters.search"
                  />
                </form>
              </div>
            </div> -->
            <div class="row" style="width:100%">
              <div class=" col-md-1  search-icon ">
                <i class="uil-search"></i>
              </div>
              <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filters.search"
                  autofocus="off" :placeholder="$t('general.search')" />
              </div>
            </div>
            <div class="d-flex">
              <!-- Shorting -->
              <a href="#" class="btn btn-icon btn-hover btn-circle" :uk-tooltip="$t('general.sort_list')" title=""
                aria-expanded="false">
                <i class="uil-list-ui-alt"></i>
              </a>
              <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up" class="uk-dropdown">
                <ul class="uk-nav uk-dropdown-nav">
                  <li @click="sortOrder('number_of_users', 'asc')">
                    <a href="javascript:void(0)">Kişi Sayısı Artan</a>
                  </li>
                  <li @click="sortOrder('number_of_users', 'desc')">
                    <a href="javascript:void(0)">Kişi Sayısı Azalan</a>
                  </li>
                  <li @click="sortOrder('created_at', 'asc')">
                    <a href="javascript:void(0)"> Talep Tarihi Artan</a>
                  </li>
                  <li @click="sortOrder('created_at', 'desc')">
                    <a href="javascript:void(0)"> Talep Tarihi Azalan</a>
                  </li>
                </ul>
              </div>
              <a href="javascript:void(0)" class="btn btn-icon btn-hover  btn-circle" aria-expanded="false"
                :uk-tooltip="$t('general.filter_list')">
                <i class="uil-filter"></i>
              </a>
              <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up" class="uk-dropdown">
                <label>Durum</label>
                <ul class="uk-nav uk-dropdown-nav">
                  <li>
                    <div class="d-flex align-items-center">
                      <input type="radio" v-model="status_selected" name="purchase_status_type"
                        id="purchase_status_type_all" value="all" />
                      <label class="font-weight-light ml-2" for="purchase_status_type_all">Tüm</label>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <input type="radio" v-model="status_selected" name="purchase_status_type"
                        id="purchase_status_type_0" value="0" />
                      <label class="font-weight-light ml-2" for="purchase_status_type_0">{{ getPurchaseStatus(0)
                      }}</label>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <input type="radio" v-model="status_selected" name="purchase_status_type"
                        id="purchase_status_type_1" value="1" />
                      <label class="font-weight-light ml-2" for="purchase_status_type_1">{{ getPurchaseStatus(1)
                      }}</label>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <input type="radio" v-model="status_selected" name="purchase_status_type"
                        id="purchase_status_type_2" value="2" />
                      <label class="font-weight-light ml-2" for="purchase_status_type_2">{{ getPurchaseStatus(2)
                      }}</label>ddd
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">Kurum Bilgileri</th>
                <th scope="col">Katalog Eğitimi</th>
                <th scope="col">Kişi Sayısı/Tutar</th>
                <th scope="col">İndirim</th>
                <th scope="col">Talep Tarihi</th>
                <th scope="col">{{ $t("general.status") }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody class="list">
              <!--user item-->
              <tr v-for="(item, index) in formattedItems" :key="'purchase_item_row_' + index">
                <th scope="row">
                  <div class="media align-items-center">
                    <div>
                      <div class="avatar-parent-child" style="width: max-content">
                        <img alt="Image placeholder" :src="item.company_logo" class="avatar rounded-circle" />
                        <span class="avatar-child avatar-badge bg-success"></span>
                      </div>
                    </div>
                    <div class="media-body ml-4">
                      <a href="javascript:void(0)" class="name h6 mb-0 text-sm">{{ item.company_name }}</a>
                      <small class="d-block font-weight-bold font-black-50">#{{ item.company_type }}</small>
                    </div>
                  </div>
                </th>
                <td scope="row">
                  <div class="media-body ml-4">
                    <a href="javascript:void(0)" class="name h6 mb-0 text-sm">{{
                      item.training_name
                    }}</a>
                    <small class="d-block font-weight-bold font-black-50">#{{ item.training_id }}</small>
                  </div>
                </td>
                <td>
                  {{
                    item.number_of_users +
                    " / " +
                    parseFloat(item.total_amount) +
                    " TL"
                  }}
                </td>
                <td>
                  {{
                    item.discount_percentage > 0
                    ? parseFloat(item.discount_percentage) +
                    "% / " +
                    parseFloat(item.discount_amount) +
                    "TL"
                    : "-"
                  }}
                </td>
                <td>{{ item.created_at }}</td>
                <td :style="item.status_id == 2
                    ? 'color:green;font-weight: bold;'
                    : item.status_id == 0
                      ? 'color:indianred;font-weight: bold;'
                      : ''
                  ">
                  <i class="uil-clock-eight mr-1" v-if="item.status_id == 1"></i>{{ item.status }}
                </td>
                <td :class="item.status_id != 1 ? 'text-center' : 'text-right'">
                  <!-- Actions -->
                  <div class="actions ml-3" v-if="item.status_id == 1">
                    <a @click="statusChange(item.id, 0, index)" class="btn btn-icon btn-hover btn-sm btn-circle"
                      uk-tooltip="Reddet" title="" aria-expanded="false">
                      <i class="uil-minus-circle text-danger"></i>
                    </a>
                    <a @click="statusChange(item.id, 2, index)" class="btn btn-icon btn-hover btn-sm btn-circle"
                      uk-tooltip="Onayla" title="" aria-expanded="false">
                      <i class="uil-check text-success"></i>
                    </a>
                  </div>
                  <span v-else>-</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination v-if="pagination && pagination.hasOwnProperty('links')" :pagination="pagination"
          @changePage="setPage($event)"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/core/services";
import moment from "moment";
import Pagination from "@/assets/components/Pagination";

import {
  GET_ITEMS,
  UPDATE_ITEM_BY_ID,
  ERROR,
} from "@/core/services/store/REST.module";

import purchaseModule, {
  MODULE_NAME as PURCHASE_MODULE_NAME,
  GET_REQUEST_COUNT as GET_PURCHASE_USER_REQUEST_COUNT,
} from "@/core/services/store/training_purchase.module";

const _PURCHASE_MODULE_NAME = PURCHASE_MODULE_NAME;

export default {
  name: "PurchaseRequestsList",
  components: { Pagination },
  data() {
    return {
      successMessage: null,
      errorMessage: null,
      restURL: "api/training-purchase",
      restStatusURL: "api/training-purchase/status/",
      items: [],
      pagination: null,
      status_selected: "all",
      filters: {
        page: 1,
        per_page: 10,
        sort: "id",
        order: "desc",
        search: "",
        status_id: null,
      },
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_PURCHASE_MODULE_NAME, purchaseModule);
  },
  computed: {
    formattedItems: {
      get() {
        let results = [];
        if (!this.items.data) return [];
        this.pagination = this.items;
        let items = this.items.data;
        for (let i = 0; i < items.length; i++) {
          let currentItem = items[i];
          if (currentItem) {
            results.push({
              id: currentItem.id,
              company_logo: currentItem.company.logo_link,
              company_name: currentItem.company.name,
              company_type: currentItem.company.type,
              training_name: currentItem.training.name,
              training_id: currentItem.training.id,
              training_price: currentItem.training.price,
              discount_amount: currentItem.discount_amount,
              discount_percentage: currentItem.discount_percentage,
              number_of_users: currentItem.number_of_users,
              total_amount: currentItem.net_amount,
              status: this.getPurchaseStatus(currentItem.status_id),
              status_id: currentItem.status_id,
              created_at: currentItem.created_at
                ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
                : "-",
            });
          }
        }
        return results;
      },
    },
    error: {
      get() {
        return this.$store.getters[ERROR];
      },
      set(value) { },
    },
  },
  methods: {
    filterResult() {
      this.$store
        .dispatch(GET_ITEMS, {
          url: this.restURL,
          filters: {
            page: this.filters.page,
            perPage: this.filters.per_page,
            sort: this.filters.sort,
            order: this.filters.order,
            search: this.filters.search,
            status_id: this.filters.status_id,
          },
        })
        .then((result) => {
          if (!self.error) {
            this.items = result.data;
          } else self.errorMessage = self.error;
        });
    },
    getPurchaseStatus(id) {
      switch (id) {
        case 0:
          return "Onay verilmedi";
          break;
        case 1:
          return "Yanıt bekleniyor";
          break;
        case 2:
          return "Onay verildi";
          break;
        default:
          return "-";
          break;
      }
    },
    statusChange(id, status_id, index) {
      let self = this;
      let formData = new FormData();
      formData.append("status_id", status_id);
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: this.restStatusURL + id,
          contents: formData,
          acceptPromise: true,
        })
        .then((result) => {
          if (!self.error) {
            self.filterResult();
            self.successMessage = self.$t("general.successfully_updated");
            self.getUserPurchaseRequestCount();
          } else self.errorMessage = self.error;
        });
    },
    getUserPurchaseRequestCount() {
      this.$store.dispatch(
        _PURCHASE_MODULE_NAME + "/" + GET_PURCHASE_USER_REQUEST_COUNT,
        {
          url: "api/purchase-request-count",
        }
      );
    },
    sortOrder(sort, order) {
      this.filters.order = order;
      this.filters.sort = sort;
      this.filterResult();
    },
    setPage(page) {
      let self = this;
      self.filters.page = page;
      this.filterResult();
    },
    resetMessages() {
      this.errorMessage = null;
      this.successMessage = null;
    },
  },
  mounted() {
    if (!this.CatalogTrainingVisible) {
      return false;
    }
    this.filterResult();
  },
  watch: {
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessage() {
      this.debouncedResetValues();
    },
    "filters.search": function (val) {
      this.debouncedFilterSearch();
    },
    status_selected(val) {
      if (val == "all") this.filters.status_id = null;
      else this.filters.status_id = val;
      this.filterResult();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 3000);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>
